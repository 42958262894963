
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Row, Col, Cell, CellGroup, Image, Icon } from "vant";
import MyNavbar from "@/components/MyNavbar.vue";
import PersonalPopup from "./PersonalPopup.vue";

@Component({
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Icon.name]: Icon,
    [Row.name]: Row,
    [Col.name]: Col,
    MyNavbar,
    PersonalPopup,
  },
})
export default class Index extends Mixins(Mixin) {
  public pic: string | undefined = "";
  public nickname: string | undefined = "请编辑";
  public sex = "请编辑";
  public birthday: string | Date | undefined = "请编辑";
  public city = {
    currentProvince: "",
    currentCity: "",
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public hobby: any = [{ describe: "请添加", id: 0 }];
  public hobbies: any = [];
  public info = {
    show: false,
    title: "",
  };
  public userInfo: member.UserInformationRes = {};
  public isRealName: boolean | undefined = false; // 当前用户是否实名制
  public personKey = 1;
  public hobbyList: dictionary.Codebook[] = [];
  mounted(): void {
    this.getUserInfo();
    this.getUserRealName();
  }

  go(): void {
    return;
  }
  // 获取兴趣爱好字典信息
  recieveHobby(): void {
    this.hobby = [];
    this.InterfaceCacheModule.getData({
      cacheKey: "performance.codeBook",
      apiMethod: this.$api.dictionaryApi.performance.codeBook,
      callback: ({ data }) => {
        this.hobbyList = data.HobbyEnum;
        data.HobbyEnum.forEach((item: any) => {
          this.hobbies.map((items: any) => {
            if (item.name == items) {
              this.hobby.push(item);
            }
          });
        });
      },
    });
    // this.hobby.length > 0 ? this.hobby : [{ describe: "请添加", id: 0 }];
  }
  /*
   *获取用户信息
   */
  getUserInfo(): void {
    this.hobbies = [];
    this.$api.memberApi.setting.selUserInfo(({ data }) => {
      this.userInfo = data;
      this.pic = data.headImg;
      this.nickname = data.nickName ? data.nickName : "请编辑";
      if (data.sex) {
        this.sex = data.sex == "SEX_GIRL" ? "女" : "男";
      } else {
        this.sex = "请编辑";
      }
      this.birthday = data.birthday ? this.formate(data.birthday) : "请编辑";
      setTimeout(() => {
        this.city.currentProvince = data.currentProvince
          ? data.currentProvince
          : "";
        this.city.currentCity = data.currentCity ? data.currentCity : "";
      }, 50);
      if (data.hobby) {
        this.hobbies = data.hobby;
      }
      this.recieveHobby();
    });
  }
  getUserRealName(): void {
    this.$api.memberApi.setting.getAccountInfo(({ data }) => {
      this.isRealName = data.authentication;
    });
  }
  //将时间毫秒数转年月日
  formate(time: string | number | Date): string | Date {
    const t = new Date(time);
    // 日期格式
    const format = "Y-m-d";
    const year = t.getFullYear();
    // 由于 getMonth 返回值会比正常月份小 1
    const month = t.getMonth() + 1;
    const day = t.getDate();
    const hash = {
      Y: year,
      m: month,
      d: day,
    };
    return `${hash.Y}-${this.fillZero(hash.m)}-${this.fillZero(hash.d)}`;
    console.log(hash, 493058);
  }
  /*
   * 补零处理
   */
  fillZero(num: number): string {
    let value = num < 10 ? "0" + num.toString() : num.toString();
    return value;
  }

  /*
   *弹窗修改名称
   */
  editorName(): void {
    this.$router.push(`/personal-data/editor-name?username=${this.nickname}`);
  }
  // 性别提示
  sexToasts(): void {
    this.$toast("已实名认证，不可修改");
  }
  //生日提示
  birthdayToasts(): void {
    this.$toast("生日只能修改一次");
  }

  /*
   *编辑
   */
  edito(name: string): void {
    this.personKey++;
    this.info.show = true;
    this.info.title = name;
    if (name == "city") {
      this.InterfaceCacheModule.getData({
        cacheKey: "region.getRegionList",
        apiMethod: this.$api.dictionaryApi.region.getRegionList,
        callback: ({ data }) => {
          this.DictionaryModule.SET_addresses_PERSIST(data);
          let asc: Array<dictionary.RegionDto> = [];
          this.DictionaryModule.addresses.forEach((item) => {
            if (item) {
              asc.push(item as any);
              if (item.children) {
                item.children.forEach((items) => {
                  if (items.children) {
                    delete items.children;
                  }
                });
              }
            }
          });
          this.DictionaryModule.TWO_DATA(asc);
        },
      });
    }
  }
  /*
   *选择结果
   */
  choose(): void {
    // if (data.type == "head") {
    //   console.log("头像");
    // } else if (data.type == "sex") {
    //   this.sex = data.value || "";
    // } else if (data.type == "birthday") {
    //   this.birthday = data.value;
    // } else if (data.type == "city") {
    //   this.city = data.value;
    // } else if (data.type == "hobby") {
    //   this.hobby = data.list;
    //   console.log(this.hobby);
    // }
    this.getUserInfo();
  }
  sexChoose(data: member.UserInformationRes): void {
    this.hobby = [];
    this.userInfo = data;
    this.pic = data.headImg;
    this.nickname = data.nickName ? data.nickName : "请编辑";
    if (data.sex) {
      this.sex = data.sex == "SEX_GIRL" ? "女" : "男";
    } else {
      this.sex = "请编辑";
    }
    this.birthday = data.birthday ? this.formate(data.birthday) : "请编辑";
    setTimeout(() => {
      this.city.currentProvince = data.currentProvince
        ? data.currentProvince
        : "";
      this.city.currentCity = data.currentCity ? data.currentCity : "";
    }, 50);
    if (data.hobby) {
      this.hobbies = data.hobby;
    }
    this.hobbyList.forEach((item: any) => {
      this.hobbies.map((items: any) => {
        // console.log(item, items, 6666);
        if (item.name == items) {
          this.hobby.push(item);
        }
      });
    });
    // this.recieveHobby();
  }
}
