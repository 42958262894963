
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import Account from "@/views/Mine/Set/Account.vue";

@Component({
  components: {
    Account,
  },
})
export default class ExternalAccountPage extends Mixins(Mixin) {}
