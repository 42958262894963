
import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Popup, Cell, CellGroup, Icon, Field, Row, Col, Picker } from "vant";
@Component({
  components: {
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Icon.name]: Icon,
    [Field.name]: Field,
    [Row.name]: Row,
    [Col.name]: Col,
    [Picker.name]: Picker,
  },
})
export default class Certificate extends Mixins(Mixin) {
  @Prop({
    type: Object,
    default: {
      show: false,
    },
  })
  public info!: { show: boolean };
  @Prop({
    type: Boolean,
    default: true,
  })
  public isArea!: boolean;
  @Emit("choose")
  select(
    data: dictionary.GBdDict | dictionary.Codebook
  ): dictionary.GBdDict | dictionary.Codebook {
    return data;
  }

  public columns: dictionary.Codebook[] = [];
  public phone = [
    { name: "中国大陆", id: 86 },
    { name: "中国台湾", id: 886 },
    { name: "中国澳门", id: 853 },
    { name: "中国香港", id: 852 },
  ];
  public value: { code?: string; name?: string; describe?: string } = {
    code: "",
    name: "",
    describe: "",
  };
  public num = this.phone[0];
  created(): void {
    this.recieveColumns();
  }
  recieveColumns(): void {
    this.InterfaceCacheModule.getData({
      cacheKey: "performance.codeBook",
      apiMethod: this.$api.dictionaryApi.performance.codeBook,
      callback: ({ data }) => {
        this.columns = data.CertTypeEnum;
      },
    });
  }
  change(
    e: string,
    value: { code: string; name: string; describe: string }
  ): void {
    this.value = value;
  }
  /*
   *选择电话区域
   */
  sureCode(item: { name: string; id: number }): void {
    this.num = item;
  }
  /*
   *关闭
   */
  close(): void {
    this.info.show = false;
  }
  /*
   *确认
   */
  submit(): void {
    if (this.isArea) {
      if (!this.value.name) {
        this.value = this.columns[0];
      }

      this.select(this.value);
    } else {
      // this.select(this.num);
    }

    this.close();
  }
}
