
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Cell, CellGroup, Image, Field, Row, Uploader } from "vant";
import StatusBar from "@/components/StatusBar.vue";

@Component({
  components: {
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Field.name]: Field,
    [Row.name]: Row,
    [Uploader.name]: Uploader,
    StatusBar,
  },
})
export default class Complain extends Mixins(Mixin) {
  public message = "";
  public fileList = [];
  go(): void {
    console.log(987);
  }
  onClickRight(): void {
    console.log(555);
  }
  onClickLeft(): void {
    this.$router.go(-1);
  }
  afterRead(file: any): void {
    // 此时可以自行将文件上传至服务器
    console.log(file);
    console.log(888, this.fileList);
  }
}
