
import { Component, Mixins } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";
import { Mixin } from "@/core/mixins/mixin";
import MyNavbar from "@/components/MyNavbar.vue";
import uploadFile from "@/plugins/upload";
import * as imageConversion from "image-conversion";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    MyNavbar,
  },
})
export default class EditPhoto extends Mixins(Mixin) {
  public cropper: any = null;
  public canvas: any = null;
  public croppShow = false;
  public img = "";
  public data = {};

  file: any = null;
  realTime(): void {
    (this.$refs.cropper as HTMLFormElement).getCropData((data: string) => {
      this.file = data;
    });
  }
  // 确认裁剪
  sureCropper() {
    let fileData = this.dataURLtoFile(this.file, "headImg.png");
    imageConversion.compressAccurately(fileData, 100).then((blob) => {
      let file = new File([blob], fileData.name, {
        type: blob.type,
      });
      console.log(blob, file);
      uploadFile(file).then((data) => {
        this.$api.memberApi.setting.updateUserInfo(
          {
            headImg: data,
          },
          ({ data }) => {
            monitorEvent("MyData_ChangeImageSuc", "成功更换头像"); // 埋点：个人资料页，成功更换头像
            this.UserModule.IMG_CHANGE_PERSIST(data.headImg);
            this.$router.push(`/personal-data/index`);
          },
          (error) => {
            this.$toast("头像上传失败");
          }
        );
      });
    });
  }
  dataURLtoFile(dataurl: string, filename: string): File {
    let arr = dataurl.split(",");
    let mime = arr[0].match(/:(.*?);/)![1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new window.File([u8arr], filename, { type: mime });
  }
}
