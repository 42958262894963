
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Cell, CellGroup, Image, Field, Row } from "vant";
import StatusBar from "@/components/StatusBar.vue";

@Component({
  components: {
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Field.name]: Field,
    [Row.name]: Row,
    StatusBar,
  },
})
export default class About extends Mixins(Mixin) {
  public nameList = [
    { name: "关于保利票务", type: "ABOUT_POLY_TICKETING" },
    { name: "用户服务协议", type: "POLY_TICKET_USER_SERVICES_AGREEMENT" },
    { name: "隐私政策", type: "PRIVACY_CLAUSE" },
    { name: "证照信息", type: "LICENSE_INFO" },
  ];

  goNotice(data: { name: string; type: string }): void {
    this.$router.push(
      `/personal-data/notice?name=${data.name}&type=${data.type}`
    );
  }
  onClickRight(): void {
    console.log(555);
  }
  onClickLeft(): void {
    this.$router.go(-1);
  }
}
